import { Email } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import FadeIn from "../../animations/FadeIn";
import { TextInput } from "../../components/Forms";
import styles from "./styles.module.scss";
import gsap from "gsap";
import { sendEmail } from "../../utils/email";
import Loader from "../../components/Loader";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup.string("Enter your password").required("Full name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string("Enter your password")
    .min(8, "Phone should be of minimum 8 characters length"),
  message: yup
    .string("Enter your password")
    .min(8, "Message should be of minimum 8 characters length")
    .required("Message is required"),
});

const Contact = () => {
  const formRef = React.useRef();

  const [isSending, setIsSending] = React.useState(false);
  const [done, setDone] = React.useState(false);

  const handleSubmit = async () => {
    try {
      setIsSending(true);
      const { name, email, phone, message } = formik.values;
      await sendEmail({ name, email, phone, message });
      setDone(true);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const buttonDelay = useMemo(() => ({ delay: 0 }), []);

  React.useEffect(() => {
    gsap.fromTo(
      formRef.current,
      { opacity: 0, y: 75 },
      {
        scrollTrigger: {
          trigger: formRef.current,
          scroller: "main",
        },
        y: 0,
        opacity: 1,
        duration: 1,
        ease: "none",
      }
    );
  }, []);

  React.useEffect(() => {
    if (!isSending) return;
    gsap.fromTo(
      formRef.current,
      { opacity: 1 },
      {
        scrollTrigger: {
          trigger: formRef.current,
          scroller: "main",
        },
        y: 0,
        opacity: 0.25,
        duration: 1,
        ease: "none",
      }
    );
  }, [isSending]);

  const getButtonMessage = () => {
    if (done) return "done!";
    if (isSending) return "sending";
    return "send";
  };

  return (
    <Grid
      container
      className={styles.container}
      data-scroll-section
      id="contact"
    >
      <Grid
        item
        xs={12}
        className={styles.titleContainer}
        data-scroll
        data-scroll-call="contact"
      >
        <FadeIn to={useMemo(() => ({ delay: 1 }), [])}>
          <Typography variant="h2" className={styles.title}>
            CONTACT US
          </Typography>
        </FadeIn>
        <FadeIn
          to={useMemo(() => ({ delay: 1 }), [])}
          className={styles.subtitle}
        >
          <Typography variant="p">
            We want to hear from you! Please, leave your message using this form
            and we'll get back to you as soon as we can.
          </Typography>
        </FadeIn>
      </Grid>
      <form
        className={styles.form}
        ref={formRef}
        onSubmit={formik.handleSubmit}
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput
                  id="filled-error"
                  variant="filled"
                  label="Full Name"
                  name={"name"}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  autoComplete="off"
                  required={true}
                  disabled={isSending || done}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="filled-error"
                  variant="filled"
                  label="Email"
                  name={"email"}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  autoComplete="off"
                  required={true}
                  disabled={isSending || done}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="filled-error"
                  variant="filled"
                  label="Telephone Number"
                  name={"phone"}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.phone}
                  autoComplete="off"
                  disabled={isSending || done}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              id="filled-error"
              variant="filled"
              label="Message"
              multiline={true}
              minRows={10}
              name={"message"}
              onChange={formik.handleChange}
              value={formik.values.message}
              autoComplete="off"
              required={true}
              disabled={isSending || done}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
          </Grid>
          {!isSending && !done && (
            <Grid item xs={12} className={styles.buttonContainer}>
              <FadeIn to={buttonDelay}>
                <Button
                  type="submit"
                  className={styles.button}
                  disabled={isSending || done}
                >
                  {getButtonMessage()}
                </Button>
              </FadeIn>
            </Grid>
          )}
        </Grid>
      </form>
      <div className={styles.emailContainer}>
        {(isSending || done) && <Loader show={isSending || done} done={done} />}
        {done && (
          <div className={styles.done}>
            Your message has been sent. We will contact you shortly!
          </div>
        )}
        <span className={styles.email}>
          <Email style={{ color: "#0DBFD8" }} /> E-mail: play@trickgs.com
        </span>
      </div>
      <div className={styles.line}></div>
    </Grid>
  );
};

export default Contact;
