export const sendEmail = async (details) => {
  let formData = new FormData();
  formData.append("name", details.name);
  formData.append("mail", details.mail);
  formData.append("phone", details.phone);
  formData.append("message", details.message);

  let response = await fetch("http://trickgs.com/sendTrickMail2.php", {
    method: "POST",
    body: formData,
  });
  let result = await response.json();
  return result;
};
