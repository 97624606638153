import React from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { AppContext } from "../../providers/AppContextProvider";
import Footer from "../Footer";
import NavBar from "../NavBar";
import styles from "./styles.module.scss";

const Layout = ({ children }) => {
  const { scroll } = useLocomotiveScroll();

  const { setSection } = React.useContext(AppContext);

  React.useEffect(() => {
    scroll.on("call", (e) => {
      setSection(e);
    });
  }, [scroll, setSection]);

  if (!scroll) return null;

  return (
    <div className={styles.app}>
      <NavBar />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
