import React from "react";
import Expertice from "./Expertice";
import Technologies from "./Technologies";
import styles from "./styles.module.scss";

const AboutUs = () => {
  return (
    <div className={styles.container} data-scroll-section id="about-us">
      <Expertice />
      <Technologies />
      <div className={styles.line}></div>
    </div>
  );
};

export default AboutUs;
