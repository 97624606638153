import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect } from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";

gsap.registerPlugin(ScrollTrigger);

export const Context = React.createContext();

const GSAPProvider = ({ children }) => {
  const { scroll } = useLocomotiveScroll();
  const [isReady, setIsReady] = React.useState(false);

  useEffect(() => {
    if (!scroll) return;

    scroll.on("scroll", (args) => {
        ScrollTrigger.update();
    });

    ScrollTrigger.scrollerProxy("main", {
        scrollTop(value) {
        return arguments.length && value !== 0
          ? scroll.scrollTo(value, 0, 0)
          : scroll.scroll.instance.scroll.y;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
    });

    const update = () => {
      scroll.update();
    };

    ScrollTrigger.addEventListener("refresh", update);
    ScrollTrigger.refresh();
    setIsReady(true);
    
    return () => {
      ScrollTrigger.removeEventListener("refresh", update);
    };
  }, [scroll]);

  return <Context.Provider value={{ isReady }}>{children}</Context.Provider>;
};

export default GSAPProvider;
