import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import vector2 from "../../assets/images/vector2.png";
import twok from "./images/2k.png";
import konami from "./images/konami.png";
import nn from "./images/nn.png";
import oneway from "./images/oneway.png";
import photobutler from "./images/photobutler.png";
import revolve from "./images/revolve.png";
import skillz from "./images/skillz.png";
import sprocket from "./images/sprocket.png";
import squareenix from "./images/squareenix.png";
import gearbox from "./images/gearbox.png";

import Slider from "../../components/Slider";
import FadeIn from "../../animations/FadeIn";
import { Grid, Typography } from "@mui/material";
import gsap from "gsap";

const Clients = () => {

  const vectorRef = React.useRef();

  React.useEffect(() => {
    gsap.fromTo(
      vectorRef.current,
      { opacity: 0 },
      {
        opacity: 0.3,
        duration: 3,
        ease: "none",
        delay: 2,
      }
    );
  }, []);

  const getClients = () => {
    const clients = [
      { image: twok, name: "2k", maxWidth: "150px", mobileWith: "100px" },
      { image: skillz, name: "skillz", mobileWith: "200px" },
      { image: nn, name: "nn", maxWidth: "150px", mobileWith: "100px" },
      { image: konami, name: "konami", mobileWith: "200px" },
      { image: squareenix, name: "squareenix", mobileWith: "200px" },
      { image: oneway, name: "oneway", mobileWith: "200px" },
      { image: sprocket, name: "sprocket", mobileWith: "200px" },
      { image: revolve, name: "revolve", mobileWith: "200px" },
        { image: photobutler, name: "photobutler", mobileWith: "150px" },
        { image: gearbox, name: "gearbox", mobileWith: "250px" },
    ];
    return clients;
  };

  const clients = getClients();
  return (
    <div className={styles.container} data-scroll-section>
      <Grid container>
        <Grid item xs={12}>
          <FadeIn>
            <Typography className={styles.title}>OUR CLIENTS</Typography>
          </FadeIn>
              </Grid>

                  <Grid item xs={12} className={styles.clients}>
                      <FadeIn
                          from={useMemo(() => ({ y: 100 }), [])}
                          to={useMemo(() => ({ y: 0 }), [])}
                  >
                          <Slider speed={6000} reverse={true} data={clients} />
                      </FadeIn>
                  </Grid>
        <img className={styles.vector} src={vector2} alt="" ref={vectorRef}/>
        <div className={styles.line}></div>
      </Grid>
    </div>
  );
};

export default Clients;
