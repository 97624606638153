import classNames from "classnames";
import React from "react";

import "./styles.scss";

const Loader = ({ show, done }) => {
  if (!show) return null;
  return (
    <div className={classNames("loader", { active: show })}>
      <div className={classNames("check", { active: done })}>
        <span className="check-one"></span>
        <span className="check-two"></span>
      </div>
    </div>
  );
};

export default Loader;
