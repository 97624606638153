import React from "react";
import styles from "./styles.module.scss";
import gsap from "gsap";

const Item = ({ image, title, text }) => {
  const containerRef = React.useRef();
  const imageRef = React.useRef();
  const titleRef = React.useRef();
  const textRef = React.useRef();

  React.useEffect(() => {
    gsap.fromTo(
      imageRef.current,
      { opacity: 0 },
      {
        scrollTrigger: {
          trigger: imageRef.current,
          scroller: "main",
        },
        opacity: 1,
        duration: 2,
        ease: "none",
      }
    );
  }, []);

  React.useEffect(() => {
    gsap.fromTo(
      [titleRef.current, textRef.current],
      { y: 50 },
      {
        scrollTrigger: {
          trigger: titleRef.current,
          scroller: "main",
        },
        y: 0,
        duration: 1,
        ease: "none",
      }
    );
  }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      <img className={styles.image} src={image} alt={title} ref={imageRef} />
      <div className={styles.title} ref={titleRef}>
        {title}
      </div>
      <div className={styles.text} ref={textRef}>
        {text}
      </div>
    </div>
  );
};

export default Item;
