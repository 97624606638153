import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import vector1 from "../../assets/images/vector1.png";
import SplitText from "../../assets/libs/split3.min.js";
import gsap from "gsap";
import classNames from "classnames";
import { useRef } from "react";

const Hero = () => {
  const vectorRef = useRef();

  useEffect(() => {
    const split = new SplitText(".animate-hero", {
      type: "lines",
      linesClass: "linesChildren",
    });

    gsap.fromTo(
      split.lines,
      {
        y: 100,
        opacity: 0,
      },
      {
        y: 0,
        duration: 1.75,
        opacity: 1,
        stagger: 0.1,
        ease: "power2",
        delay: 1,
      }
    );
  }, []);

  React.useEffect(() => {
    gsap.fromTo(
      vectorRef.current,
      { opacity: 0 },
      {
        opacity: 0.6,
        duration: 3,
        ease: "none",
        delay: 2,
      }
    );
  }, []);

  return (
    <Grid container className={styles.container} id="home">
      <Grid item xs={12} className={styles.textContainer} id="hero-text">
        <div className={classNames(styles.title, "animate-hero")}>
          TIME TO PLAY
        </div>
        <div className={classNames(styles.subtitle, "animate-hero")}>
          We grew up playing videogames
        </div>
        <div className={classNames(styles.subtitle, "animate-hero")}>
          Now we make them.
        </div>
      </Grid>
      <img className={styles.vector} src={vector1} alt="" ref={vectorRef} />
    </Grid>
  );
};

export default Hero;
