import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./styles.scss";
import { useMediaQuery } from "@mui/material";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

export default function CustomSlider({ data }) {
  const settings = {
    infinite: true,
    speed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    variableWidth: true,
    cssEase: "linear",
      arrows: false,
      draggable: false,
      pauseOnHover: false,
    swipe: false,
  };

  const isMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <React.Fragment>
          {!isMobile ? <Slider {...settings}>
        {data.map((client, index) => (
          <div key={index}>
            <img
              src={client.image}
              alt=""
              className="client"
              style={{
                maxWidth: isMobile ? client.mobileWith : client.maxWidth,
              }}
            />
            ;
          </div>
        ))}
          </Slider>
              :
              <Carousel autoPlay={true} interval={1500} transtionTime={1000} infiniteLoop={true} showArrows={false}
                  showThumbs={false} stopOnHover={false} showStatus={false} showIndicators={false}>
                  {data.map((client, index) => (
                      <div key={index} style={{
                          height: "100%",
                          display: "flex"
                      } }>
                          <img
                              src={client.image}
                              alt=""
                              className="client"
                              style={{
                                  maxWidth: isMobile ? client.mobileWith : client.maxWidth,
                                  margin: "auto"
                              }}
                          />
                          ;
                      </div>
                  ))}
              </Carousel>

          }
    </React.Fragment>
  );
}
