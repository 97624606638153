import React from "react";

import gameDevelopment from "./images/gameDevelopment.png";
import softwareDevelopment from "./images/softwareDevelopment.png";
import web3 from "./images/web3.png";
import uiDesign from "./images/uiDesign.png";

import { Grid, Typography } from "@mui/material";
import Item from "./Item";
import styles from "./styles.module.scss";
import FadeIn from "../../../animations/FadeIn";

const Expertice = () => {
  return (
    <Grid
      container
      className={styles.container}
      data-scroll
      data-scroll-call="about-us"
    >
      <Grid item xs={12}>
        <FadeIn>
          <Typography className={styles.title}>WE ARE EXPERTS IN</Typography>
        </FadeIn>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Item
          image={gameDevelopment}
          title="Game Development"
                  text="Creating Games is our passion and a big part of who we are.
With more than 10 years of experience bringing projects to life, you can trust us all the way from Game Design to Go-Live activies, including Production, Development, Art and much more."
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Item
          image={softwareDevelopment}
          title="Software Development"
                  text="Our team of ~250 professionals can cover many fields of expertise.
We can help with Web Development, Mobile Applications, Virtual Reality, Augmented Reality, Cloud computing and everything inbetween."
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Item
          image={web3}
          title="Web3"
                  text="By getting involved early in the Web3 space, we had the chance to create some of the very first DeFI games. 
Over the last few years we have acquired vast experience working on Crosschain and Multichain solutions, Multiverse, NFT Marketplaces, Tokens and Web3 Games. "
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Item
          image={uiDesign}
          title="UI/UX Design"
                  text="Our team of professionals specializing in Graphic Design, UI/UX and Branding can either work on standalone Design projects or integrate directly into the Development teams, including Web, Mobile or Games."
        />
      </Grid>
    </Grid>
  );
};

export default Expertice;
