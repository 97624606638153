import React from "react";

import dotnet from "./images/dotnet.png";
import node from "./images/node.png";
import react from "./images/react.png";
import unity from "./images/unity.png";
import unreal from "./images/unreal.png";

import styles from "./styles.module.scss";
import { useRef } from "react";
import gsap from "gsap";

const Technologies = () => {
  const containerRef = useRef();

  React.useEffect(() => {
    gsap.fromTo(
      containerRef.current.querySelectorAll("img"),
      { scale: 0 },
      {
        scrollTrigger: {
          trigger: containerRef.current,
          scroller: "main",
        },
        scale: 1,
        duration: 1,
        ease: "none",
      }
    );
  }, []);

  return (
    <div className={styles.container} ref={containerRef} id="technologies">
      <img src={unity} alt="unity" />
      <img src={unreal} alt="unreal technology" style={{ height: "100px" }} />
      <img src={react} alt="react js" />
      <img src={node} alt="nodejs" />
      <img src={dotnet} alt="microsoft .net" style={{ height: "70px" }} />
    </div>
  );
};

export default Technologies;
