import { TextField } from "@mui/material";
import React from "react";
import styles from "./styles.module.scss";

const TextInput = React.forwardRef(
  (
    {
      label,
      name,
      value,
      onChange,
      multiline,
      minRows,
      error,
      required,
      disabled,
      helperText,
    },
    ref
  ) => {
    return (
      <TextField
        ref={ref}
        disabled={disabled}
        name={name}
        variant="outlined"
        InputProps={{
          style: { color: "#FFFFFF" },
          classes: {
            notchedOutline: error
              ? styles.notchedOutlineError
              : styles.notchedOutline,
          },
        }}
        inputProps={{ autoComplete: "mui-input-autocomplete" }}
        autoComplete="nope"
        spellCheck="false"
        sx={{
          width: "100%",
          marginBottom: "1.75rem",
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
              border: "none",
            },
          },
          ".MuiOutlinedInput-input.Mui-disabled": {
            color: "#FFFFFF",
            WebkitTextFillColor: "#FFFFFF",
          },
          ".MuiFormHelperText-root": {
            color: "#ca3f72",
            fontSize: "0.65rem",
            position: "absolute",
            bottom: "-22px",
            left: "-12px",
          },
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            color: "#FFF",
            opacity: 0.7,
            fontWeight: 500,
            top: "-15px",
            left: "-10px",
            fontSize: "1.2rem",
          },
        }}
        margin="dense"
        label={`${label} ${required ? "*" : ""}`}
        onChange={(event) => {
          onChange != null && onChange(event);
        }}
        multiline={multiline}
        minRows={minRows}
        value={value}
        helperText={helperText}
      />
    );
  }
);

export default TextInput;
