import { Grid, useMediaQuery } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import Logo from "../Logo";
import NavLinks from "./NavLinks";
import styles from "./styles.module.scss";
import gsap from "gsap";

const NavBar = () => {
  const [hasBackground, setHasBackground] = useState(false);
  const { scroll } = useLocomotiveScroll();
  const navigate = useNavigate();
  const ref = useRef();
  const isMobile = useMediaQuery("(max-width:600px)");
  const logoRef = useRef();
  const linksRef = useRef();

  useEffect(() => {
    if (!scroll) return;
    scroll.on("scroll", (event) => {
      const scrollY = event.scroll.y;
      setHasBackground(scrollY > 0);
    });
  }, [scroll]);

  useEffect(() => {
    gsap.fromTo(
      [logoRef.current, linksRef.current],
      { y: -100, opacity: 0 },
      { opacity: 1, duration: 1, y: 0 }
    );
  }, []);

  return (
    <Grid
      ref={ref}
      container
      className={classNames({
        [styles.container]: true,
        [styles.mobile]: isMobile,
      })}
      style={{
        backgroundColor: hasBackground ? "#111111" : "transparent",
      }}
      id="navbar"
    >
      <Logo onClick={() => navigate("/")} width={"125px"} ref={logoRef} />
      <NavLinks ref={linksRef} isMobile={isMobile}/>
    </Grid>
  );
};

export default NavBar;
