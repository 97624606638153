import { createContext, useContext, useState } from "react";
import { Context } from "./GsapProvider";

export const AppContext = createContext({});

const AppContextProvider = ({ children }) => {
  const [section, setSection] = useState("home");
  const [navClicked, setNavClicked] = useState(false);
  const gsap = useContext(Context);

  if (!gsap.isReady) return null;
  
  return (
    <AppContext.Provider
      value={{
        section,
        setSection,
        navClicked,
        setNavClicked,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
