import React, { useContext } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { AppContext } from "../../../providers/AppContextProvider";

const NavItem = ({ text, id, showActive, handleClick }) => {
  const context = useContext(AppContext);

  return (
    <span
      onClick={() => handleClick(id)}
      className={classNames({
        [styles.text]: true,
        [styles.active]: context.section === id && showActive,
      })}
    >
      {text}
    </span>
  );
};

export default NavItem;
