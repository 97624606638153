import { Grid, useMediaQuery } from "@mui/material";
import classNames from "classnames";
import React, { useContext } from "react";
import NavItem from "../NavItem";
import styles from "./styles.module.scss";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { AppContext } from "../../../providers/AppContextProvider";

const NavLinks = React.forwardRef((props, ref) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [collapse, setCollapse] = useState(isMobile);
  const { scroll } = useLocomotiveScroll();
  const { setSection } = useContext(AppContext);

  const handleClick = (id) => {
    const element = document.getElementById(`${id}`);
    const scrollTo = id === "home" ? "top" : element.offsetTop;
    scroll.scrollTo(scrollTo);
    setSection(id);
    if (isMobile) {
      setCollapse(!collapse);
    }
  };

  return (
    <Grid
      container
      className={classNames({
        [styles.container]: true,
        [styles.mobile]: isMobile,
        [styles.collapse]: collapse,
      })}
    >
      {collapse ? (
        <React.Fragment>
          <MenuIcon
            style={{ color: "#ffffff", fontSize: "2em" }}
            onClick={() => setCollapse(!collapse)}
          />
        </React.Fragment>
      ) : (
        <div
          ref={ref}
          className={classNames({
            [styles.links]: true,
            [styles.collapse]: collapse,
          })}
        >
          {!collapse && isMobile && (
            <CloseIcon
              style={{ color: "#ffffff", alignSelf: "flex-end" }}
              onClick={() => setCollapse(true)}
            />
          )}
          <NavItem
            text="home"
            isActive
            id="home"
            showActive={true}
            handleClick={handleClick}
          />
          <NavItem
            text="about us"
            id="about-us"
            showActive={true}
            handleClick={handleClick}
          />
          <NavItem
            text="contact"
            id="contact"
            showActive={true}
            handleClick={handleClick}
          />
        </div>
      )}
    </Grid>
  );
});

export default NavLinks;
