import gsap from "gsap";
import React, { useEffect, useRef } from "react";

const FadeIn = ({ from, to, scrollTrigger, children, className }) => {
  const el = useRef();

  useEffect(() => {
    gsap.fromTo(
      el.current,
      { opacity: 0, ...from },
      {
        scrollTrigger: {
          trigger: el.current,
          scroller: "main",
          ...scrollTrigger,
        },
        opacity: 1,
        duration: 2,
        ease: "none",
        delay: 0.5,
        ...to,
      }
    );
  }, [from, scrollTrigger, to]);

  return (
    <span className={className} ref={el}>
      {children}
    </span>
  );
};

export default React.memo(FadeIn);
