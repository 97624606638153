import React from "react";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import styles from "./styles.module.scss";

import "../assets/styles/locomotive-scroll.css";

export const Context = React.createContext({});

const LocomotiveProvider = ({ children }) => {
  const ref = React.useRef(null);
  
  return (
    <LocomotiveScrollProvider
      options={{
        smooth: true,
        multiplier: 0.5,
        resetNativeScroll: true,
      }}
      watch={[]}
      containerRef={ref}
    >
      <main data-scroll-container ref={ref} className={styles.container}>
        {children}
      </main>
    </LocomotiveScrollProvider>
  );
};

export default LocomotiveProvider;
