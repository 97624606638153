import React from "react";
import logo from "../../assets/images/logo.png";
import styles from "./styles.module.scss";

const Logo = React.forwardRef(({ width }, ref) => {
  return (
    <div className={styles.logo} ref={ref}>
      <img src={logo} alt="trick gaming" style={{ width }} />
    </div>
  );
});

export default Logo;
