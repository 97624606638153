import React from "react";
import Logo from "../Logo";
import NavItem from "../NavBar/NavItem";
import styles from "./styles.module.scss";

const Footer = () => {
  return (
    <div className={styles.footer} data-scroll-section>
      <div className={styles.logo}>
        <Logo width={"125px"} />
        <div className={styles.copyright}>
          © Trick Studio. All Rights Reserved 2022 -
        </div>
      </div>
      <div className={styles.links}>
        <NavItem text="home" id="home" showActive={false} />
        <NavItem text="about us" id="about-us" showActive={false} />
        <NavItem text="contact" id="contact" showActive={false} />
      </div>
    </div>
  );
};

export default Footer;
