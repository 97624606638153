import { Layout, Section } from "./components";
import { Hero, AboutUs, Clients, Contact } from "./sections";
import AppContextProvider from "./providers/AppContextProvider";
import LocomotiveProvider from "./providers/LocomotiveProvider";
import GSAPProvider from "./providers/GsapProvider";

function App() {
  return (
    <LocomotiveProvider>
      <GSAPProvider>
        <AppContextProvider>
          <Layout>
            <Section id="home">
              <Hero />
            </Section>
            <Section id="about-us">
              <AboutUs />
            </Section>
            <Section id="clients">
              <Clients />
            </Section>
            <Section id="contact">
              <Contact />
            </Section>
          </Layout>
        </AppContextProvider>
      </GSAPProvider>
    </LocomotiveProvider>
  );
}

export default App;
